// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-2JgoJ>ul{display:flex;flex-wrap:nowrap;align-items:stretch;margin:0;padding:0;list-style:none}.container-2JgoJ.containerY-2hYBg>ul{flex-direction:column}.container-2JgoJ.containerX-1gItI>ul{flex-direction:row;height:100%}.scrollable-3DXhZ{-webkit-overflow-scrolling:touch}.containerX-1gItI.scrollable-3DXhZ{overflow-x:scroll;overflow-y:hidden}.container-2JgoJ.scrollPerf-1FGI3>ul{pointer-events:none}.container-2JgoJ.isScrolling-_FwGB .v-page-item-region{opacity:0.2}.item-2JCZ8{flex:none}.itemX-2XIQ3{height:100%;display:flex;flex-direction:column;justify-content:center}.itemY-3DIRN{width:100%}\n", ""]);
// Exports
exports.locals = {
	"container": "container-2JgoJ",
	"containerY": "containerY-2hYBg",
	"containerX": "containerX-1gItI",
	"scrollable": "scrollable-3DXhZ",
	"scrollPerf": "scrollPerf-1FGI3",
	"isScrolling": "isScrolling-_FwGB",
	"item": "item-2JCZ8",
	"itemX": "itemX-2XIQ3",
	"itemY": "itemY-3DIRN"
};
module.exports = exports;
