/* eslint-disable react/no-find-dom-node */
import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';

export default class HorizontalScrollWheel extends PureComponent {
  constructor() {
    super();

    this.delta = 0;
    this.raf = null;
  }

  componentDidMount() {
    // eslint-disable-next-line compat/compat
    document.querySelector('.a-content').addEventListener('wheel', this.onWheel);
    const target = findDOMNode(this);

    // deltaMode is representing the unit of the delta values scroll amount
    this.deltaModeCorrections = {
      [WheelEvent.DOM_DELTA_PIXEL]: 1,
      [WheelEvent.DOM_DELTA_LINE]: parseInt(window.getComputedStyle(target).fontSize, 10),
      [WheelEvent.DOM_DELTA_PAGE]: target.offsetWidth,
    };
  }

  componentWillUnmount() {
    // eslint-disable-next-line compat/compat
    document.querySelector('.a-content').removeEventListener('wheel', this.onWheel);
    cancelAnimationFrame(this.raf);
  }

  onWheel = event => {
    const { locked } = this.props;
    if (locked) {
      return;
    }

    event.preventDefault();
    this.delta += this.getDelta(event);

    if (!this.raf) {
      this.raf = requestAnimationFrame(() => {
        if (!locked) {
          findDOMNode(this).scrollLeft += this.delta;
        }

        this.delta = 0;
        this.raf = null;
      });
    }
  };

  getDelta(ev) {
    const delta = Math.abs(ev.deltaX) > Math.abs(ev.deltaY) ? ev.deltaX : ev.deltaY;
    return delta * this.deltaModeCorrections[ev.deltaMode];
  }

  render() {
    const { children } = this.props;

    return children;
  }
}

HorizontalScrollWheel.propTypes = {
  children: PropTypes.node.isRequired,
  locked: PropTypes.bool.isRequired,
};
