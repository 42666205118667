/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { cloneElement, isValidElement, Children } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Tile = props => {
  const { className, type, children, style, ...restChildrenProps } = props;

  return (
    <div className={classNames('v-pane-tile', className, type && `l-${type}`)} style={style}>
      {Children.map(children, c => (isValidElement(c) ? cloneElement(c, restChildrenProps) : c))}
    </div>
  );
};

Tile.propTypes = {
  style: PropTypes.object,
  tileWrapperProps: PropTypes.object,
  children: PropTypes.node,
  className: PropTypes.string,
  type: PropTypes.string,
};

export default Tile;
