import React, { memo, useMemo } from 'react';
import { object, bool, string, func, number } from 'prop-types';
import classNames from 'classnames';
import { MAX_TILE_HEIGHT, FEATURE_CAPABILITY_NEWSSTAND } from 'app-constants';
import Features from 'config/features';
import BrowserEnvironment from 'instances/browser_environment';
import { getManifestBody } from 'helpers/manifest';
import { renderItemContent } from 'helpers/renderItemContent';
import { getPortraitTileTemplate, getDefaultTileTemplate } from 'helpers/providerHelpers';
import { createItemUriFromManifest } from 'helpers/prettyUrl';
import TilesStore from 'stores/TilesStore';
import { isProviderPortraitImageCapable, prefillSelector, providerById } from 'selectors/providers';
import { getManifestImageHref } from 'selectors/itemImage';
import { getManifest } from 'selectors/tiles';
import ItemPriceContainer from 'containers/ItemPriceContainer';
import Link from 'components/Link';
import ItemCreditsPriceContainer from 'containers/ItemCreditsPriceContainer';
import styledModule from 'helpers/styledModule';
import useAlt from 'hooks/useAlt';
import FeatureCapabilitiesStore from 'stores/FeatureCapabilitiesStore';
import { hasCapability } from 'selectors/capabilities';
import DefaultManifestContent from './components/DefaultManifestContent';
import PortraitManifestContent from './components/PortraitManifestContent';
import ManifestDropdownWrapper from './ManifestDropdownWrapper';
import CSS from './ManifestContainer.scss';

const StyledItemPriceContainer = styledModule(ItemPriceContainer)(CSS.micropaymentsItemPrice);
const StyledItemCreditsPriceContainer = styledModule(ItemCreditsPriceContainer)(
  CSS.creditsItemPrice,
);

const getPortraitImageDimensions = (tileHeight) => {
  const isMobile = BrowserEnvironment.isMobile();
  const width = isMobile ? window.innerWidth : 310;

  if (isMobile) {
    return {
      width,
      height: 368, // On mobile the manifest content is a fixed height of 368px
    };
  }

  const height = tileHeight ? Math.min(tileHeight, MAX_TILE_HEIGHT) : null; // If we don't know the tile height it is estimated

  return {
    width,
    height,
  };
};

const getPortraitImage = ({ hideImage, manifest, tileHeight }) => {
  const isPortraitImgCapable = prefillSelector(isProviderPortraitImageCapable)(
    manifest.provider.id,
  );

  if (!isPortraitImgCapable || hideImage) {
    return null;
  }

  const isMobile = BrowserEnvironment.isMobile();
  const maxAspectRatio = isMobile ? 1.1 : 0.8;
  const portraitImageDimension = getPortraitImageDimensions(tileHeight);

  const smartCropOptions = {
    width: portraitImageDimension.width,
    widthInterval: isMobile, // On desktop the width is a fixed value
    height: portraitImageDimension.height,
    heightInterval: !isMobile && portraitImageDimension.height !== MAX_TILE_HEIGHT, // On mobile the height is a fixed value
  };

  return getManifestImageHref(manifest, {
    smartCrop: Features.smartCropImages,
    smartCropOptions,
    criteria: {
      minWidth: portraitImageDimension.width * 0.8,
      minHeight: portraitImageDimension.height * 0.7,
      hasCredits: false,
      maxAspectRatio,
    },
  });
};

const ManifestContainer = memo(
  ({ analytics, hideImage, className, onNavigate, tileHeight, itemId }) => {
    const [tilesState, featureCapabilitiesState] = useAlt([TilesStore, FeatureCapabilitiesStore]);
    const hasNewsStand = hasCapability(featureCapabilitiesState, FEATURE_CAPABILITY_NEWSSTAND);

    const manifest = getManifest(tilesState.tiles, itemId);
    const providerId = manifest.provider.id;
    const provider = prefillSelector(providerById)(providerId);

    const portraitImage = useMemo(() => getPortraitImage({ hideImage, manifest, tileHeight }), [
      hideImage,
      manifest,
      tileHeight,
    ]);

    const defaultManifestProps = {
      analytics,
      itemURI: `/${createItemUriFromManifest(manifest)}`,
    };

    return (
      <div className={classNames(className, 's-success', `provider-${provider.id}`)}>
        <div className="manifest-top-bar">
          {hasNewsStand ? (
            <Link
              href={`/issue/${provider.id}`}
              className="item-provider"
              data-testid="provider-with-link"
            >
              {provider.name}
            </Link>
          ) : (
            <div className="item-provider" data-testid="provider-without-link">
              {provider.name}
            </div>
          )}
          <StyledItemPriceContainer itemId={itemId} />
          <StyledItemCreditsPriceContainer itemId={itemId} />
          <ManifestDropdownWrapper itemId={manifest.id} analytics={analytics} />
        </div>
        {portraitImage ? (
          <PortraitManifestContent
            {...defaultManifestProps}
            itemContent={renderItemContent(
              getManifestBody(manifest),
              getPortraitTileTemplate(provider.id),
            )}
            onOpen={onNavigate}
            portraitImage={portraitImage}
          />
        ) : (
          <DefaultManifestContent
            {...defaultManifestProps}
            itemContent={renderItemContent(
              getManifestBody(manifest),
              getDefaultTileTemplate(provider.id),
            )}
            onOpen={onNavigate}
            hideImage={hideImage}
            manifest={manifest}
          />
        )}
      </div>
    );
  },
);

ManifestContainer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  analytics: object.isRequired,
  hideImage: bool,
  className: string,
  onNavigate: func,
  tileHeight: number,
  itemId: string.isRequired,
};

ManifestContainer.defaultProps = {
  hideImage: false,
  className: 'v-manifest item-manifest item',
  onNavigate: () => {},
  tileHeight: null,
};

ManifestContainer.displayName = 'ManifestContainer';

export default ManifestContainer;
