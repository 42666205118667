// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-1GMIO{contain:layout}.container-1GMIO.x-yax8D .content-236WO{height:100%;display:flex;align-items:center}\n", ""]);
// Exports
exports.locals = {
	"container": "container-1GMIO",
	"x": "x-yax8D",
	"content": "content-236WO"
};
module.exports = exports;
