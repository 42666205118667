import React from 'react';
import TilesStore from 'stores/TilesStore';
import useAlt from 'hooks/useAlt';
import useHasCreditsCapability from 'hooks/useHasCreditsCapability';
import { string } from 'prop-types';
import { Label } from '@blendle/lego';
import formatCurrency from 'helpers/formatCurrency';
import styledModule from 'helpers/styledModule';
import { pipe, prop, anyPass, isNil, equals } from 'ramda';
import CSS from './styles.scss';

const PriceLabel = styledModule(Label)(CSS.label);

const articleIsFree = pipe(prop('price'), anyPass([isNil, equals(0)]));

const ItemCreditsPriceContainer = ({ itemId, className }) => {
  const tilesState = useAlt([TilesStore], ([state]) => state);
  const hasCreditsCapability = useHasCreditsCapability();
  const tile = tilesState.tiles.get(itemId);

  if (!hasCreditsCapability || !tile || tile.item_purchased || articleIsFree(tile)) {
    return null;
  }

  return (
    <PriceLabel
      backgroundGradient="razzmatazzGradient"
      color="snowWhite"
      className={className}
      data-testid="price-label"
    >
      {formatCurrency(tile.price, { amountIsInCents: true })}
    </PriceLabel>
  );
};

ItemCreditsPriceContainer.propTypes = {
  className: string,
  itemId: string.isRequired,
};

ItemCreditsPriceContainer.defaultProps = {
  className: '',
};

export default ItemCreditsPriceContainer;
