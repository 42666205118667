/* eslint-disable react/no-find-dom-node */
import React, { PureComponent, Children } from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import { throttle, debounce } from 'lodash';
import classNames from 'classnames';
import smoothScroll from 'helpers/scroll';
import Scroller from 'react-list';
import browserEnv from 'instances/browser_environment';
import { innerHeight, innerWidth } from 'helpers/innerSize';
import { isNil } from 'ramda';
import CSS from './ScrollList.scss';
import ScrollListItem from './ScrollListItem';

const OFFSET_SIZE_KEYS = {
  x: 'offsetWidth',
  y: 'offsetHeight',
};

// larger on iPad since it doesn't trigger onScroll so often, causing empty spaces while scrolling
const PAGE_SIZE = browserEnv.isIPad() ? 15 : 5;
const hasTouch = browserEnv.hasTouch();

export default class ScrollList extends PureComponent {
  constructor(props) {
    super();

    this.state = {
      scrolling: false,
      availableTileSize: { width: 0, height: 0 },
    };

    this.list = null;
    this.children = Children.toArray(props.children);
    this.visibleRange = [0, 5];

    this.throttleOnScroll = throttle(this.onScroll.bind(this), 200, {
      leading: true,
      trailing: false,
    });
    this.debounceOnScrollEnd = debounce(this.onScrollEnd.bind(this), 250, {
      leading: false,
      trailing: true,
    });
    this.throttleOnResize = throttle(this.onResize.bind(this), 100, {
      leading: false,
      trailing: true,
    });

    this.setListRef = ref => {
      this.list = ref;
    };
  }

  componentDidMount() {
    this.getScrollParent().addEventListener('scroll', this.throttleOnScroll);
    window.addEventListener('resize', this.throttleOnResize);

    this.updateAvailableTileSize();

    const { initialIndex } = this.props;

    if (initialIndex) {
      // the react-list component doesn't always scrolls to the correct index when being called
      // the first time, since it doesn't has the sizes of all the tiles,
      // therefor passing it a callback that scroll again in case the index is not yet in view
      const to = initialIndex;
      this.scrollTo(to, 'easeInQuart', () => {
        this.scrollTo(to, 'easeOutQuart');
      });
    }

    // in the next UI frame to prevent 'cannot dispatch during dispatch'
    this.posEventsTimeout = setTimeout(() => {
      this.invokePositionEvents(this.getVisibleRange(true));
    });
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.children = Children.toArray(nextProps.children);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.children.length !== this.children.length) {
      // in the next UI frame to prevent 'cannot dispatch during dispatch'
      clearTimeout(this.posEventsTimeout);
      this.posEventsTimeout = setTimeout(() => {
        this.invokePositionEvents(this.getVisibleRange(true));
      });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.posEventsTimeout);
    cancelAnimationFrame(this.raf);

    this.throttleOnScroll.cancel();
    this.debounceOnScrollEnd.cancel();
    this.throttleOnResize.cancel();

    this.getScrollParent().removeEventListener('scroll', this.throttleOnScroll);
    window.removeEventListener('resize', this.throttleOnResize);
  }

  onResize() {
    this.updateAvailableTileSize();
  }

  onScroll() {
    if (this.raf) {
      return;
    }

    this.raf = requestAnimationFrame(() => {
      this.raf = null;
      this.onScrollRAF();
    });
  }

  onScrollRAF() {
    const { onScroll } = this.props;
    this.visibleRange = this.list.getVisibleRange(true);

    if (onScroll) {
      onScroll(this.visibleRange);
    }

    this.invokePositionEvents(this.visibleRange);

    this.setState({ scrolling: true });
    this.debounceOnScrollEnd();
  }

  onScrollEnd() {
    this.setState({ scrolling: false });
  }

  // Return the indices of the first and last items that are at all visible in the viewport.
  getVisibleRange(forceUpdate = false) {
    if (forceUpdate) {
      this.visibleRange = this.list.getVisibleRange();
    }
    return this.visibleRange;
  }

  getScrollParent = () => {
    const { scrollWindow } = this.props;

    return scrollWindow ? window : findDOMNode(this);
  };

  getItemSize = index => {
    if (!this.list) {
      return 340;
    }

    const { axis } = this.props;

    const offsetKey = OFFSET_SIZE_KEYS[axis];
    const domChildren = findDOMNode(this.list).children;
    // child is rendered, pick its dimension!
    if (domChildren[index]) {
      return domChildren[index][offsetKey];
    }
    // pick the center element, the first element is in most cases an explanation card
    return domChildren[Math.floor(domChildren.length / 2)][offsetKey];
  };

  updateAvailableTileSize() {
    const element = findDOMNode(this.list);
    this.setState({
      availableTileSize: {
        height: innerHeight(element),
        width: innerWidth(element),
      },
    });
  }

  invokePositionEvents(visibleRange) {
    const [indexOfFirstVisibleElement, indexOfLastVisibleElement] = visibleRange;

    if (isNil(indexOfFirstVisibleElement) || isNil(indexOfLastVisibleElement)) {
      return;
    }

    const { onNearEnd, numberOfItemsPerRowOnYAxis, axis } = this.props;
    const childrenCount = this.children.length;

    if (onNearEnd) {
      // Determine how close we should be to the end of the list before calling onNearEnd
      // This will be at the latest 10 items before we have reached the end of the list
      // The threshold can be reached earlier if there are more than 10 items visible in the UI,
      // for instance on big screens
      const fetchMoreThreshold =
        childrenCount - Math.max(indexOfLastVisibleElement - indexOfFirstVisibleElement, 10);

      // Make sure the threshold is above zero. This prevents unintended calls to onNearEnd when the list is being initialized
      const fetchMoreThresholdPositive = Math.max(fetchMoreThreshold, 0);

      if (axis === 'x') {
        if (indexOfLastVisibleElement > fetchMoreThresholdPositive) {
          onNearEnd(visibleRange);
        }
      }

      if (axis === 'y') {
        // In the Micropayment Kiosk and Discover Issues page we render two items per row, we need to take that into account
        if (indexOfLastVisibleElement > fetchMoreThresholdPositive / numberOfItemsPerRowOnYAxis) {
          onNearEnd(visibleRange);
        }
      }
    }
  }

  // Put the element at index at the top of the viewport.
  scrollTo(index, smooth = false, callback) {
    if (!smooth) {
      this.list.scrollTo(index);
      return;
    }

    // calculate the duration based on the scroll distance
    const from = this.list.getScrollPosition();
    const to = this.list.getSpaceBefore(index);
    const duration = Math.min(Math.max(200, Math.abs(from - to) / 5), 1750);

    const easing = typeof smooth === 'string' ? smooth : 'easeInOutQuart';

    const { axis } = this.props;

    smoothScroll.scroll(axis, this.getScrollParent(), to, {
      duration,
      easing,
      callback,
    });
  }

  renderItems = (items, ref) => (
    <ul className="pane-contents" ref={ref}>
      {items}
    </ul>
  );

  renderItem = (index, key) => {
    const { axis, threshold } = this.props;
    const { scrolling, availableTileSize } = this.state;

    const node = this.children[index];
    const itemClassName = classNames(
      CSS.item,
      CSS[`item${axis.toUpperCase()}`],
      node.props.className,
    );

    const [min, max] = this.visibleRange;
    const isVisible = index >= min - threshold && index <= max + threshold;

    return (
      <ScrollListItem
        key={node.key || key}
        axis={axis}
        className={itemClassName}
        scrolling={scrolling}
        availableSize={availableTileSize}
        visible={isVisible}
      >
        {node}
      </ScrollListItem>
    );
  };

  render() {
    const { axis, scrollWindow, className } = this.props;
    const { scrolling } = this.state;

    const containerClassName = classNames(
      CSS.container,
      CSS[`container${axis.toUpperCase()}`],
      className,
      {
        [CSS.scrollable]: !scrollWindow,
        [CSS.isScrolling]: scrolling,
        [CSS.scrollPerf]: scrolling && !hasTouch,
      },
    );

    return (
      <div className={containerClassName}>
        <Scroller
          type="simple"
          axis={axis}
          pageSize={PAGE_SIZE}
          threshold={1000}
          ref={this.setListRef}
          length={this.children.length}
          scrollParentGetter={this.getScrollParent}
          itemsRenderer={this.renderItems}
          itemRenderer={this.renderItem}
          itemSizeGetter={this.getItemSize}
        />
      </div>
    );
  }
}

ScrollList.propTypes = {
  axis: PropTypes.oneOf(['x', 'y']),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  scrollWindow: PropTypes.bool,
  threshold: PropTypes.number,
  initialIndex: PropTypes.number,
  onScroll: PropTypes.func.isRequired,
  onNearEnd: PropTypes.func.isRequired,
  numberOfItemsPerRowOnYAxis: PropTypes.number,
};

ScrollList.defaultProps = {
  axis: 'y',
  threshold: 0,
  initialIndex: 0,
  className: '',
  scrollWindow: false,
  numberOfItemsPerRowOnYAxis: 1,
};
