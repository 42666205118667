import React, { PureComponent, Children } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import BrowserEnv from 'instances/browser_environment';
import ScrollList from 'components/ScrollList';
import Tile from 'components/Tile';
import Spinner from 'components/Loading';
import NavigationArrows from './NavigationArrows';
import HorizontalScrollWheel from './HorizontalScrollWheel';
import CSS from './TilePane.scss';

class TilePane extends PureComponent {
  constructor(props) {
    super(props);

    this.onNextItem = this.onArrowClick.bind(this, 'item', 1);
    this.onNextPage = this.onArrowClick.bind(this, 'page', 1);
    this.onPrevItem = this.onArrowClick.bind(this, 'item', -1);
    this.onPrevPage = this.onArrowClick.bind(this, 'page', -1);

    this.state = {
      scrollPos: [0, 0],
      arrows: {
        prev: false,
        next: false,
      },
    };

    this.setListRef = ref => {
      this.list = ref;
    };
  }

  componentDidMount() {
    this.updateArrows();
    const { scrollPos } = this.state;

    window.scrollTo(...scrollPos);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { active } = this.props;
    if (active && !nextProps.active) {
      this.setState({
        // eslint-disable-next-line compat/compat
        scrollPos: [window.scrollX, window.scrollY],
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { children, active } = this.props;
    const { scrollPos } = this.state;
    if (Children.count(prevProps.children) !== Children.count(children)) {
      this.updateArrows();
    }

    // restore window scroll position after activate change
    if (active && !prevProps.active) {
      window.scrollTo(...scrollPos);
    }
  }

  onScroll = visibleRange => {
    const { onScroll } = this.props;

    this.updateArrows(visibleRange);

    if (onScroll) {
      onScroll(visibleRange);
    }
  };

  onArrowClick(type, increment) {
    const [start, end] = this.list.getVisibleRange(true);
    let to = start + increment;
    if (type === 'page') {
      to = increment === 1 ? end : start - (end - start);
    }
    this.list.scrollTo(to, true);
  }

  getChildren() {
    const { children, loading } = this.props;
    const nextChildren = Children.toArray(children);

    if (loading) {
      nextChildren.push(
        <Tile type="loading" key="loading">
          <Spinner />
        </Tile>,
      );
    }
    return nextChildren;
  }

  scrollTo(...args) {
    this.list.scrollTo(...args);
  }

  updateArrows(visibleRange) {
    const [start, end] = visibleRange || this.list.getVisibleRange();
    const numberOfVisibleItems = end - start;

    this.setState({
      arrows: {
        prev: start !== 0,
        /*
         * The -1 because sometimes the last item is partially visible, but it's still
         * the end of the bundle so we don't want to show the 'next' arrow
         */
        next: Children.count(this.getChildren()) > end + numberOfVisibleItems - 1,
      },
    });
  }

  renderScrollList(props) {
    const { numberOfItemsPerRowOnYAxis, initialIndex, onNearEnd } = this.props;

    return (
      <ScrollList
        ref={this.setListRef}
        initialIndex={initialIndex}
        onScroll={this.onScroll}
        onNearEnd={onNearEnd}
        numberOfItemsPerRowOnYAxis={numberOfItemsPerRowOnYAxis}
        {...props}
      >
        {this.getChildren()}
      </ScrollList>
    );
  }

  renderNavigationArrows() {
    const { showButtons, active } = this.props;
    const { arrows } = this.state;

    if (showButtons && active) {
      return (
        <NavigationArrows
          onNextItem={this.onNextItem}
          onNextPage={this.onNextPage}
          onPrevItem={this.onPrevItem}
          onPrevPage={this.onPrevPage}
          isNextEnabled={arrows.next}
          isPrevEnabled={arrows.prev}
        />
      );
    }
    return null;
  }

  render() {
    const { innerRef, addSpaceForSubNavigation, className, orientation, active } = this.props;

    if (orientation === 'horizontal') {
      return (
        <div
          ref={innerRef}
          className={classNames(
            className,
            CSS.containerHorizontal,
            'v-horizontal-tile-pane',
            addSpaceForSubNavigation
              ? 'with-space-for-sub-navigation'
              : 'without-space-for-sub-navigation',
          )}
        >
          <HorizontalScrollWheel locked={!active}>
            {this.renderScrollList({
              axis: 'x',
              scrollWindow: false,
              className: classNames(CSS.scrollList, CSS.scrollListHorizontal),
            })}
          </HorizontalScrollWheel>
          {this.renderNavigationArrows()}
        </div>
      );
    }

    return (
      <div
        ref={innerRef}
        className={classNames(className, CSS.containerVertical, 'v-vertical-tile-pane', {
          [CSS.disabled]: !active,
        })}
      >
        {this.renderScrollList({
          axis: 'y',
          scrollWindow: true,
          className: classNames(CSS.scrollList, CSS.scrollListVertical),
        })}
        {this.renderNavigationArrows()}
      </div>
    );
  }
}

TilePane.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  loading: PropTypes.bool,
  active: PropTypes.bool,
  showButtons: PropTypes.bool,
  initialIndex: PropTypes.number,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  onScroll: PropTypes.func,
  onNearEnd: PropTypes.func,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  numberOfItemsPerRowOnYAxis: PropTypes.number,
  addSpaceForSubNavigation: PropTypes.bool,
};

TilePane.defaultProps = {
  className: '',
  children: undefined,
  active: true,
  loading: false,
  initialIndex: 0,
  showButtons: BrowserEnv.isDesktop() && !BrowserEnv.hasTouch(),
  orientation: BrowserEnv.isMobile() ? 'vertical' : 'horizontal',
  innerRef: undefined,
  numberOfItemsPerRowOnYAxis: 1,
  onScroll: undefined,
  onNearEnd: undefined,
  addSpaceForSubNavigation: true,
};

export default TilePane;
