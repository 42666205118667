// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".manifestDropdownWrapper-1AF2W{position:absolute;right:0;top:0}\n", ""]);
// Exports
exports.locals = {
	"manifestDropdownWrapper": "manifestDropdownWrapper-1AF2W"
};
module.exports = exports;
