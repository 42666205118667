// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".micropaymentsItemPrice-W_Z14{position:absolute;right:15px;height:42px;top:0;margin-right:36px}.creditsItemPrice-1UqbG{position:absolute;right:51px;top:11px}\n", ""]);
// Exports
exports.locals = {
	"micropaymentsItemPrice": "micropaymentsItemPrice-W_Z14",
	"creditsItemPrice": "creditsItemPrice-1UqbG"
};
module.exports = exports;
