// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".label-36m3J{text-transform:uppercase;line-height:20px}\n", ""]);
// Exports
exports.locals = {
	"label": "label-36m3J"
};
module.exports = exports;
